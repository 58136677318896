import './App.css';
import React, { Component } from "react";
import PreMint from "./components/premint";
import Mint from "./components/mint";
import Bid from "./components/bid";
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import ReactGA from 'react-ga';


ReactGA.initialize('G-93NWZVCB97');
window && window.location && ReactGA.pageview(window.location.pathname);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { status: "PREMINT", isToggleOn: true, open: false, setOpen: false};
    this.handlePremint = this.handlePremint.bind(this);
    this.handleMint = this.handleMint.bind(this);
    this.handleBid = this.handleBid.bind(this);
    this.handleClick= this.handleClick.bind(this);


  window && window.ethereum && window.ethereum.on('accountsChanged', accounts => {
      accounts && accounts.length && this.setState(state => ({isToggleOn: !state.isToggleOn,open: !state.open}))
      // Time to reload your interface with accounts[0]!
    })
}

componentDidMount() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.get('ref') && localStorage.setItem('refferal', urlSearchParams.get('ref'));
}

handleClick() {
  this.setState(state => ({
  isToggleOn: !state.isToggleOn,
  open: !state.open
}))}



handlePremint() {this.setState(state => ({status: "PREMINT"}))}
handleMint() {this.setState(state => ({status: "MINT"}))}
handleBid() {this.setState(state => ({status: "BID"}))}

render() {
  let { status, open} = this.state;

  return (
      <div className="App-container">
        { status === "PREMINT" ? <PreMint /> : null}
        { status === "MINT"  ? <Mint /> : null}
        { status === "BID"  ? <Bid />  : null}
        <Dialog open={open} onClose={this.handleClick} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box className="wallet-modal ">
            <h3>🎉 Your Metamask connected 🎉</h3>
            <p>Your really close to activate your whitelist</p>
            <p>Need help to connect your wallet to Polygon?<a href="/doc#wtf-4">WTFAQ</a></p>
            <p>Need MATIC token to pay the minimal gas fee?<a href="/doc#wtf-2">WTFAQ</a></p>
            <p>If you need any help just join to our <a target="_blank" href="https://discord.gg/3N6xEVwn">Discord</a> channel</p>
            <p>Follow us on <a target="_blank" href="https://twitter.com/DaysWTFDAO">Twitter</a></p>
          </Box>
        </Dialog>
      </ div>
    );
  }
}

export default App;
