function Doc() {
  return (
    <div className="App-container">
      <h1>WTFAQ</h1>

      <h4 id="wtf-01">WTFAQ 1. - WHAT IS POLYGON AND WHY WE CHOSE THIS BLOCKCHAIN?</h4>
      <p>
        Polygon is an aggregating scalable solution on Ethereum supporting a multi-chain Ethereum ecosystem.
      </p>
      <p>
        The developers behind Polygon (MATIC) sought out ways to improve the speed and reduce the cost and complexities of transactions on Ethereum using a Layer 2 scaling solution. This means that Polygon acts as an add-on layer to Ethereum where transactions can be finalized, without needing to change the original blockchain layer.
      </p>
      <p>
        Your DAY is forever minted on the Polygon blockchain with a speedy process with near-zero gas fees.
      </p>

      <h4 id="wtf-02">WTFAQ 2. - HOW CAN I GET/BUY MATIC TO BE ABLE TO USE POLYGON?</h4>
      <p>
        The MATIC cryptocurrency token plays a key role in maintaining and operating Polygon, and can be used for holding, spending, sending or staking. MATIC is readily available on major exchanges including Binance and FTX.

      </p>
      <a target="blank" href="https://ftx.com/intl/trade/MATIC/USD">https://ftx.com/intl/trade/MATIC/USD</a><br/>
      <a target="blank" href="https://www.binance.com/en/buy-polygon-matic">https://www.binance.com/en/buy-polygon-matic</a>

      <h4 id="wtf-03">WTFAQ 3. - HOW TO INSTALL METAMASK IN ORDER TO CONNECT CRYPTO WALLET TO DAYS.WTF?</h4>
      <p>
        Step 1. Install MetaMask on your browser
      </p>

      <ul>
        <li>To create a new wallet with MetaMask you need to install the extension first. You can install Metamask for Chrome, Firefox, Brave and Opera browsers.</li>
        <li>Open https://metamask.io or search for “Metamask extension” using your favorite search engine.</li>
        <li>We will be using Google Chrome as an example, but the workflow is the same for all browsers.</li>
        <li>Click Chrome to install MetaMask as a Google Chrome extension.</li>
        <li>Click Add to Chrome.</li>
        <li>Click Add Extension.</li>
      </ul>

      <p>
        Step 2. Create an account
      </p>

      <ul>
        <li>Click on the MetaMask icon in the upper right corner to open the extension.</li>
        <li>To install the latest version of MetaMask, click Try it now.</li>
        <li>Click Continue.</li>
        <li>You will be asked to create a new password. Create a strong password and click Create.</li>
        <li>Don’t forget to store your password for safekeeping!</li>
        <li>Proceed by clicking Next, then accept Terms of Use.</li>
        <li>Click Reveal secret words.</li>
        <li>You will see a 12 word seed phrase. Save seed words as a file or copy them to a safe place and click Next.</li>
        <li>Reveal secret words and copy your secret backup phrase to a safe place</li>
        <li>Verify your secret phrase by selecting the previously generated phrase. When done, click Confirm.</li>
        <li>By “solving this puzzle” you are confirming that you know your secret phrase</li>
      </ul>

      <p>
        Congratulations! You have successfully created your MetaMask account. A new Ethereum wallet address was automatically generated for you!
      </p>

      <a target="blank" href="https://docs.polygon.technology/docs/develop/metamask/hello">https://docs.polygon.technology/docs/develop/metamask/hello</a>


      <h4 id="wtf-04">WTFAQ 4. - HOW CAN I CONFIGURE POLYGON BLOCKCHAIN ON MY METAMASK WALLET?</h4>
      <p>
        In order to view the flow of funds in your accounts, on the Polygon Network, you will need to configure Polygon (testnet, mainnet) URL on Metamask.
      </p>
      <p>
        To add Polygon’s Mainnet, click on the Network selection dropdown and then click on Custom RPC.
      </p>
      <p>
        It will open up a form with 2 tabs on the top, Settings and Info. In the Settings tab you can add
      </p>
      <ul>
        <li>Polygon Mainnet in the Network Name field,</li>
        <li>URL https://polygon-rpc.com/ in the New RPC URL field,</li>
        <li>137 in Chain ID field,</li>
        <li>MATIC in Currency Symbol field and</li>
        <li>https://polygonscan.com/ in Block Explorer URL field.</li>
      </ul>

      <p>
        Once you’ve added the information click on Save. You will be directly switched to Polygon’s Mainnet now in the network dropdown list. You can now close the dialog.
      </p>

      <a target="blank" href="https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/">https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/</a>

      <h4 id="wtf-05">WTFAQ 5. - DO I HAVE TO PAY FOR THE AIRDROP OF 10,950 DAYS ?</h4>
      <p>
        No, 10,950 days are for free, you just have to claim the day(s). You only have to pay the Gas Fee of Polygon network in order to use this blockchain.
      </p>

      <h4 id="wtf-06">WTFAQ 6. - HOW MANY AIRDROP DAY(S) CAN I MINT AFTER BEING WHITELISTED SUCCESSFULLY?</h4>
      <p>
        It depends on your status in our Shiller-Matrix, which is our referral tier scheme.
      </p>
      <p>
        If you help building Days.WTF community, and get people on the whitelist using your referral link, you can get access to more scarce days. Non-whitelisted addresses can only mint normal days.
      </p>
      <ul>
        <li>The rule is the following if you mint within your dedicated time-slot (Whitelist Airdrop):
          <ul>
            <li>ONE mint per address up to your referral tier.(e.g.: if you are on Level 3 in the Sciller-Matrix, then you can mint ONE day from Level 3 (year), OR ONE day from Level 2 (months), OR ONE day from Level 1 (normal day). </li>
            <li>Whitelist Airdrop opens up hourly.</li>
          </ul>
        </li>
        <li>The rule is the following if you mint outside a dedicated time-slot (Public Airdrop):
          <ul>
            <li>you can mint 10 days/address</li>
          </ul>
        </li>
      </ul>
      <p>
        If all the 10,950 days are claimed and minted on the very first day, you can mint only ONE day.
      </p>

      <h4 id="wtf-07">WTFAQ 7. - WHEN DOES THE WHITELIST AIRDROP START AND HOW LONG DOES IT TAKE?</h4>
      <p>
        Whitelist Airdrop opens up hourly and your time slot depends on your status in our Shiller-Matrix, which is our referral tier scheme. Whitelist Airdrop will start with Level 7, then Level 6 and finally will open for Level 1.
      </p>

      <h4 id="wtf-08">WTFAQ 8. - HOW MANY ADDRESS CAN BE WHITELISTED FOR THE WHITELIST AIRDROP?</h4>
      <p>
        If there are 10,950 addresses whitelisted, the smart contract terminates the whitelist airdrop period immediately. We aspire to maximise the fairness of our free airdrop.
      </p>

      <h4 id="wtf-09">WTFAQ 9. - WHAT WILL DETERMINE WHICH LEVEL I BELONG TO AND WHERE CAN I CHECK MY REFERRAL TIER LEVEL STATUS?</h4>
      <p>
        You have to do promotional work to be able to upgrade your position up to the next Level and be able to claim more scarce days. Non-whitelisted addresses can only mint normal days.
      </p>
      <p>
        The Shiller-Matrix, our incentive tier system for referrals is the following:
      </p>
      <ul>
        <li>Level 1 - normal day 		– for the folks without referrals (non-pre-whitelisted activated users)</li>
        <li>Level 2 - month 		– pre-whitelisted users start here, or people with at least 10 referrals</li>
        <li>Level 3 - year 			– at least 50 referrals</li>
        <li>Level 4 - leap day 		– at least 100 referrals</li>
        <li>Level 5 - decade 		– at least 500 referrals</li>
        <li>Level 6 - century 		– at least 2000 referrals</li>
        <li>Level 7 - millennium		– a message signed by BTC genesis block private key and tweeted at @DaysWTFDAO on Twitter </li>
      </ul>

      <h4 id="wtf-10">WTFAQ 10. - DURING AIRDROP WHAT ARE THE SCARCITY LEVELS FOR DIFFERENT DAYS?</h4>
      <p>
        The scarcity levels (tiers) of the special days are the following:
      </p>
      <ul>
        <li>Level 1.9446 normal days </li>
        <li>Level 2.	   800 months</li>
        <li>Level 3.	   400 years</li>
        <li>Level 4.	   200 leap days (aka. 29th of February)</li>
        <li>Level 5.	    80 decades</li>
        <li>Level 6.	    20 centuries</li>
        <li>Level 7.	     4 millenniums.</li>
      </ul>

      <h4 id="wtf-11">
        WTFAQ 11. - WHERE CAN I SEE THE DAYS THAT ARE AVAILABLE FOR MINTING?
      </h4>
      <p>
        There is a calendar widget on our website in order to help you to choose the prefered/available days.
      </p>
      <h4 id="wtf-12">
        WTFAQ 12. - CAN I SELL MY DAY(S) LATER ON AN NFT PLATFORM?
      </h4>
      <p>
        Yes, we have already integrated Days.WTF with OPENSEA.IO.
      </p>
      <h4 id="wtf-13">
        WTFAQ 13. - I MISSED THE WHITELIST AIRDROP AND MY ADDRESS IS NOT WHITELISTED. COULD I CLAIM AIRDROP WITHOUT BEING WHITELISTED?
      </h4>
      <p>
        Yes, you can, but you won’t have a guaranteed spot, so you may miss the free airdrop. Also, you can only mint normal calendar days (Level 1 at Shiller-Matrix). Be aware that there are only 10.950 days available for free airdrop mint.
      </p>
      <h4 id="wtf-14">
        WTFAQ 14. - WHAT IS THE SCHEDULE FOR FREE AIRDROP MINTING?
      </h4>
      <p>
        TBD
      </p>


      <h4 id="wtf-15">
        WTFAQ 15. - WHAT IS TiTS DAO??
      </h4>
      <p>
        TiTS DAO is the main governing body of the Days.WTF ecosystem.
      </p>
      <p>
        Our main goal is to set up a blockchain based decentralized crypto community where everyone owns a piece of TIME (at least one calendar day) and produce a community governance system using the wisdom of the Non-Fungible-Frens.
      </p>
      <p>
        It is perhaps the most interesting aspect of Days.WTF that most of the money from daily auctions goes to the TiTS DAO.
      </p>
      <h4 id="wtf-16">
        WTFAQ 16. - WHO IS AUTHORIZED TO MAKE DECISIONS ABOUT TiTS DAO PROPOSALS?
      </h4>
      <p>
        Everybody who owns more than 2% of the total amount of minted days can submit a new proposal. The threshold can be changed through a DAO proposal.
      </p>
      <p>
        Each DAY has one vote in the TiTS DAO, voting rights can be delegated to a third party.
      </p>
      <p>
        The on-chain voting process is the following: once the proposal has been pushed on-chain, there is a waiting period of 72 hours before votes can be cast. Voting lasts 5 days.
      </p>
      <p>
        The possibility for voting will open after whitelist Airdrop and Public Airdrop completed and the 21st DAY will be minted on daily auction.
      </p>
      <p>
        Founders have given themselves a special veto right for a certain period of time (the first 1095 days) to ensure that no malicious proposals can be passed while the project is in early maturity. This veto right will only be used if an obviously harmful governance proposal has been passed, and is intended as a last resort.
      </p>
      <h4 id="wtf-17">
        WTFAQ 17. - WHAT DOES QUADRATIC BIDDING MEAN? WHAT’S IN IT FOR ME?
      </h4>
      <p>
        It is our incentive kickback for 2nd & 3rd bidders in the daily auction of Days.WTF.
      </p>
      <p>
        It means that even if you did not win in the bidding period of the auction, you still have the chance to be rewarded for your bidding activity. Quadratic Bidding Scheme has the following distribution:
      </p>
      <ul>
        <li>16% to the second-highest bid taker</li>
        <li>8% to the third-highest bid taker</li>
        <li>60% to TiTS DAO</li>
        <li>5% to the referrer of the winning bid. In the lack of referrer the 5% goes to a charity selected by the community</li>
        <li>The Founders of Days NFT receive 11% from the auction</li>
      </ul>
      <h4 id="wtf-18">
        WTFAQ 18. HOW IS THE FEE INCOME GENERATED FROM TRADING AT OPENSEA PLATFORM DISTRIBUTED BETWEEN FOUNDERS AND TiTS DAO?
      </h4>
      <p>
        The 10% royalty from Opensea will be distributed as follows:
      </p>
      <ul>
        <li>Founders will receive 2.5% and </li>
        <li>TiTS DAO will receive 7.5%</li>
      </ul>
      <h4 id="wtf-19">
        WTFAQ 19. - CAN I MODIFY/CHANGE THE DETAILS (NAME, URL, HASH) OF THE DAY NFT I MINTED?
      </h4>
      <p>
        Sure, you can change all of them: Name / IPFS / Arweave hash.
      </p>
      <h4 id="wtf-20">
        WTFAQ 20. - WILL THERE BE DAYS MINTED FOR THE FOUNDERS OF DAYS.WTF IN ADVANCE?
      </h4>
      <p>
        There will be 1500 days minted out for the Founders of Days.WTF, proportional to the scarcity levels (tiers). This will give the Founders a say in the development at an early, critical stage.
      </p>
      <h4 id="wtf-21">
        WTFAQ 21. - ARE THE DAYS.WTF NFTS CC0?
      </h4>
      <p>
        Days.WTF is a Creative Commons Zero (CC0) license NFT project.
      </p>
      <p>
        TiTS DAO - the decentralized autonomous organization of Days.WTF - makes the ‘Days’, (the Art) available for any purpose, including commercial and noncommercial use, free of charge and without requiring permission from us.
      </p>
      <h4 id="wtf-22">
        WTFAQ 22. - HOW DOES THE DAILY AUCTION WORK?
      </h4>
      <p>
        The bidding on the daily auction will start after the free airdrop mint is completed (all available  10,950 days are minted).
      </p>
      <p>
        In every auction, 2 days are offered for auction: the actual day and a free day from the past, chosen by the highest bidder.
      </p>
      <p>
        After 24 hours, the highest bidder wins, and the next auction begins.
      </p>
      <p>
        It goes on forever.<br/>* till 9999-12-31, so there won't be more than 6M days minted, ever. Even when the end of forever is reached.
      </p>
      <p>
        The daily auctions will be closed at midnight UTC every day. There is a calendar widget where users can see the availability of the dates and select one.
      </p>
      <h4 id="wtf-23">
        WTFAQ +1 - WHAT IS ZULU TIME?
      </h4>
      <p>
        Beginning in the 1920s, nautical time zones were referred to with alphabetic characters. The Z zone is equivalent to Coordinated Universal time (UTC), which is the time at the prime or "zero" meridian (0° longitude). The phonetic alphabet refers to Z as "Zulu". It is still used in some settings (i.e. military, nautical and aviation) to refer to UTC.
      </p>







    </div>
  );
}

export default Doc;
