import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { Link } from "react-router-dom";
import './timeline.css';


export default function OppositeContentTimeline() {
  return (
    <div className="timeline-wrapper">
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineOppositeContent>
            <h4 className='timline-time'>whitelist<br/><span className='timline-utc'></span></h4>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot variant="filled" className="dot-color"> </TimelineDot>
            <TimelineConnector className="dot-color" />
          </TimelineSeparator>
          <TimelineContent>max 10,950 whitelisted address</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent> only whitelisted addresses<br/> opens up hourly <a href="/doc#wtf-14">(details)</a>, claim past days
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="inherit"> </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent><h4 className='timline-time'>whitelist airdrop <br/><span className='timline-utc'>based on your level</span></h4></TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent>
            <h4 className='timline-time'>public airdrop <br/><span className='timline-utc'></span></h4>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="inherit"> </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>max 10 days/address, claim past days <br/> max 10,950 NFTs through all airdrops</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent> every day bid for 2 days <a href="/doc#wtf-22">(details)</a> <br/> current +1 past day
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="inherit"> </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent><h4 className='timline-time'>daily bid</h4></TimelineContent>
        </TimelineItem>
      </Timeline>
    </div>
  );
}
