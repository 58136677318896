import { Link } from "react-router-dom";
import Container from '@mui/material/Container';
import React, { Component } from "react";
import ExampleDay from "./Image";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {status: "PREMINT"};
}


render() {
  let { status} = this.state;

  return (
      <div>
      <h1>Mint A Day </h1>
      <p>
      If you missed the opportunity to apply to our Whitelist in advance, you will be able to start your minting only after the addresses of all Level 2-7 have already minted the scarce days they want to get the most (6 hours already have left since the bidding session had been launched).
      </p>

      <p>Minting time scheduling:</p>
      <p>
        We open up tiers by every hour on the first minting day. It means that we will launch the minting period only for Level 7 (millenniums) first. Then in the 2nd hour we launch the minting period for Level 6 (centuries). Minting period for Level 1 (normal days) will start 6 hours later than the launch of the minting period for Level 7.
      </p>

      <p>
        1500 days will be minted out for the Founders of Days.WTF, proportional to the scarcity levels (tiers).
      </p>

      <h2>Claim your free day NOW</h2>
      <p>
        The Tiers open every hour so you might lose the opportunity to mint the rarest days. Do not forget: there is a limited number of days in each Tier. We would not like you to say: “man I wish I had got into days”
      </p>
      </ div>
    );
  }
}

export default App;
