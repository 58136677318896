import "./boxes.css";

const BoxDisplay = () => {
  return (
      <div className='box-wrapper'>
        <div className='box-border sides '>
          <h4 className='box-title'>Free Fair Airdrop</h4>
          <p className='box-descreption'>10,950. It's free. Just activate your whitelist</p>
        </div>
        <div className='box-border sides-2'>
          <h4 className='box-title'>Treasury in Time-Space DAO</h4>
          <p className='box-descreption'>1 day = 1 vote <br/> Receives 60% of bids</p>
        </div>
        <div className='box-border sides '>
          <h4 className='box-title'>Quadratic Bidding <span className='box-title-new'>New</span></h4>
          <p className='box-descreption'>16% and 8% of bids to <br/> 2nd & 3rd bidders</p>
        </div>
        <div className='box-border sides-2'>
          <h4 className='box-title'>Naturally Scarce</h4>
          <p className='box-descreption'>Days as 1/1 NFTs</p>
        </div>
        <div className='box-border sides '>
          <h4 className='box-title'>CC0</h4>
          <p className='box-descreption'>Community first, community owned</p>
        </div>
        <div className='box-border sides-2'>
          <h4 className='box-title'>On Polygon</h4>
          <p className='box-descreption'>$0.01 for a transaction</p>
        </div>
        <div className='box-border sides '>
          <h4 className='box-title'>Personal</h4>
          <p className='box-descreption'>Change name, link, hash for your day</p>
        </div>
        <div className='box-border sides-2'>
          <h4 className='box-title'>100% onchain</h4>
          <p className='box-descreption'>All visuals, all info</p>
        </div>
        <div className='box-border sides '>
          <h4 className='box-title'>Charity</h4>
          <p className='box-descreption'>TiTS DAO supports charities from T0.</p>
        </div>
      </div>
    )
  };




export default BoxDisplay;
