import { Link } from "react-router-dom";
import ConnectWallet from "../Wallet";
import SecondaryHeader from "../Banner";
import './header.css';
import { ethers } from "ethers";


function DaysHeader() {
  return (
      <div>
        <header className="App-header">
          <div className="App-logo">
            <div className="App-logo-box">
              <Link to="/">
                <div className="App-logo-background"/>
              </Link>
            </div>
          </div>
          <div className="App-header-right">
            <div className="App-link-box">
              <Link className="App-header-link" to="/dao">DAO</Link>
              <Link className="App-header-link" to="/doc">WTFAQ</Link>
            </div>
            <ConnectWallet />
          </div>
        </header>
        <SecondaryHeader main={true} data= {{
          title:"First NFT project that lets you own any day. ",
          text:"Free Fair Airdrop of 10,950 then bid for 2 days every day. Forever*",
          subText:"* till 9999-12-31",
        }}
        />
      </div>
  );
}

export default DaysHeader;
