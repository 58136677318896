function Dao() {
  return (
    <div className="App-container">
      <h2>Treasury in Time-Space (TiTS DAO)</h2>
      <p>
        Community first, community owned. Days.WTF is an experimental attempt to bootstrap an inclusive crypto community, governance and treasury, where everyone owns a piece of TIME (at least one calendar day) and produce a community governance system using the wisdom of the Non-Fungible-Frens.
      </p>
      <p>
        TiTS DAO is the main governing body of the Days.WTF ecosystem and the majority of the money from daily auctions funneled into it.
      </p>


      <h3>Examples of Proposals</h3>

      <ol className="dao-list">
        <li id="prop1" className="dao-list-item">
          Donate 10,000 MATIC for 4 Charities chosen by the community.
        </li>
        <li id="prop2" className="dao-list-item">
          In parallel with the TiTS DAO governance on-chain proposal process, create a Small Grants & Funding Pool for the development of small, one-off, or alpha version projects.
        </li>
        <li id="prop3" className="dao-list-item">
          Launching a marketing campaign on Brave Browser in order to have exposure to the crypto community.
        </li>
        <li id="prop4" className="dao-list-item">
          Involve and connect with digital artists to make DAYS unique with visual content (e.g.: cover picture on Twitter or Opensea) and support the digital creative community to make every DAY special.
        </li>
        <li id="prop5" className="dao-list-item">
          Launching a marketing campaign to promote the Polygon blockchain network.
        </li>
        <li id="prop6" className="dao-list-item">
          "Incubate multiple NFT projects and emerging artists, advance market accessibility for creators, WEB3-minded individuals and promote censorship-free expression of opinions through digital art."
        </li>
      </ol>
    </div>
  );
}

export default Dao;
