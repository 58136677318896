import { Link } from "react-router-dom";
import React, { Component } from "react";
import './wallet.css';
import { useEthers } from '@usedapp/core';
import { InjectedConnector } from '@web3-react/injected-connector';

const WalletInfo : React.FC<{ onOpen: () => void }> = props => {
  const { onOpen } = props;
  const { account, activate, deactivate } = useEthers();
  const displayName = account &&  account.substring(0,3) + "..." + account.substring(account.length - 3)
  return account ? <button className="App-header-wallet-disconnect-button" onClick={
    () => deactivate()
  }>DISCONNECT <br/> {displayName} </button> : <button className="App-header-wallet-disconnect-button" onClick={() => { const injected = new InjectedConnector({ supportedChainIds: [1,2,3,4,137,80001]}); activate(injected)}}>  Connect <br/> Metamask </button>
};


export default WalletInfo;
