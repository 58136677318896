import "./days.css";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const Days = [
  {
    id: 1,
    image: "https://days.epicday.workers.dev/placeholder/2000-01-01",
    level: "Level 7",
    title:"First day of a millenium",
    description: "max 0.04% can be minted as past day",
  },
  {
    id: 2,
    image: "https://days.epicday.workers.dev/placeholder/1900-01-01",
    level: "Level 6",
    title:"First day of a century",
    description: "max 0.20% can be minted as past day",
  },
  {
    id: 3,
    image: "https://days.epicday.workers.dev/placeholder/2020-01-01",
    level: "Level 5",
    title:"First day of a decade",
    description: "max 0.80% can be minted as past day",
  },
  {
    id: 4,
    image: "https://days.epicday.workers.dev/placeholder/2020-02-29",
    level: "Level 4",
    title:"Leap day",
    description: "max 2.00% can be minted as past day",
  },
  {
    id: 5,
    image:"https://days.epicday.workers.dev/placeholder/2021-01-01",
    level: "Level 3",
    title:"First day of a year",
    description: "max 4.00% can be minted as past day",
  },
  {
    id: 6,
    image: "https://days.epicday.workers.dev/placeholder/2021-02-01",
    level: "Level 2",
    title:"First day of a month",
    description: "max 8.00% can be minted as past day",
  },
  {
    id: 7,
    image: "https://days.epicday.workers.dev/placeholder/2021-12-20",
    level: "Level 1",
    title:"Just an ordinary day",
    description: "",
  },
];



const ExampleDays = () => {

  const templateDays = Days.map((day,i) => {
    if (Days !== null) {
        return (

          <Card key={day.id} className="day-border" sx={{ display: 'flex' }}>
            <CardMedia
              className="day-box-img"
              component="img"
              image={day.image}
              alt={day.title}
            />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent className='day-content' sx={{ flex: '1 0 auto' }}>
                  <h3 className='day-level'>{day.title}</h3>
                  <h4 className='day-sublevel'>{day.level}</h4>
                  <p className='day-description'>{day.description}</p>
                </CardContent>
              </Box>
            </Card>
        );
    }
    return null;
  });

  return <div className='days-wrapper'>{templateDays}</div>;
  };



export default ExampleDays;
