import "./image.css";
import ImageSlider from "./carousel";

const IMAGES = [
  {
    id: 1,
    src: "https://days.epicday.workers.dev/placeholder/2000-01-01?txt=Y2K didn't happen",
    alt: "Millennium",
  },
  {
    id: 2,
    src: "https://days.epicday.workers.dev/placeholder/1900-01-01?txt=NYC operates first electric bus",
    alt: "Century",
  },
  {
    id: 3,
    src: "https://days.epicday.workers.dev/placeholder/1970-01-01?txt=Zero, the beginning of Unix Time",
    alt: "Decade",
  },
  {
    id: 4,
    src: "https://days.epicday.workers.dev/placeholder/1960-02-29?txt=Tony Robbins was born",
    alt: "Leap day",
  },
  {
    id: 5,
    src: "https://days.epicday.workers.dev/placeholder/0404-01-01?txt= Last gladiator fight in Rome",
    alt: "Year",
  },
  {
    id: 6,
    src: "https://days.epicday.workers.dev/placeholder/1976-04-01?txt=Apple was founded",
    alt: "Month",
  },
  {
    id: 7,
    src: "https://days.epicday.workers.dev/placeholder/2002-08-08?txt= International Cat Day",
    alt: "Normal day",
  },
];





function ImageDisplay() {
  return (
    <div>
      <div className='image-container'>
        <ImageSlider images={IMAGES} slidesToShow={5} />
      </div>
    </div>
  );
}

export default ImageDisplay;
