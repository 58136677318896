import diagram from './diagram.png';
import "./diagram.css";



export default function DisplyDiagram() {
  return (
    <div className="diagram-wrapper"><img className="diagram-img" src={diagram} alt="diagram" /></div>

  );
}
