import "./topbar.css";



export default function DisplyDiagram() {
  return (
    <div className="topbar-wrapper">
      <p className="topbar-wrapper-text" >NFT 🐐s can free mint scarcer days (block 14200000): Crypto Punks (🥇 PFP), BAYC (🥇 IP sell), NOUNS (🥇 CC0), ASH (❤️ pushing boundary of art)</p>
    </div>

  );
}
