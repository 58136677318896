import { Link } from "react-router-dom";
import Container from '@mui/material/Container';
import React, { Component } from "react";

import ExampleDay from "./Image";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {status: "PREMINT"};
}


render() {
  let { status} = this.state;

  return (
      <div>
      <h1>Get some good karma, bid for you own DAY </h1>
      <p>
        Two Days are minted every day, one from the past that can be selected by the Creator and the second will be the actual calendar date. The Creator will be selected by auction every 24 hours, forever.
      </p>
      <p>
        This is where it gets interesting. Instead of being a limited, finite set, Days.wtf Project is designed to be infinite with a decreasing inflation rate.
      </p>
      <p>
        One interface for participating in the days auctions is Days.wtf, which is a website built and maintained by us for the universe.
      </p>
      <p>
        The auctions will be closed at midnight UTC every day and the highest bid will be the Creator of the day. The highest bid will win and get the possibility to mint the actual calendar day and an available day from the past. There will be a calendar widget where users can see the availability of the dates and select one. Every date can be selected.
      </p>

      <h2>Quadratic Bidding Scheme</h2>
      <p>
        As a consequence of our new-of-a-kind Quadratic Bidding Scheme, 16% of the winning amount goes to the second-highest bid taker (‘GreatLooser’) and 8% to the third-highest bid taker (‘BigLooser’). 5% of all the bids goes to the referrer. In the lack of referrer, the 5% goes to charity. The Founders receive 11% from the auction. The left 60% goes to the Treasury of Time (DAO).
      </p>

      <h2>What do DO’s do?</h2>
      <p>
        What is arguably most interesting about Days.WTF is where all of that 60% actually goes and what it could potentially be used for. It’s funneled into the Treasury of Time (DAO). A decentralized autonomous organization was created to govern the Days.WTF project going forward. Who does govern the DAO and controls all of that money in the Treasury? DO’s do.
      </p>

      <h2>Bid Now (1/1 of ∞)</h2>
      <p>
        Bid now for the day you want to get the most. The rarest days will be available for bidding after the minting session will be closed so you might lose the opportunity to mint scarce days. We would not like you to say: “man I wish I had got into days”
      </p>
      </ div>
    );
  }
}

export default App;
