import React, { Component } from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { ethers } from "ethers";
import WLABI from '../wlabi';
import WLADDRESS from '../address';
import './banner.css';


const { ethereum } = window;
const provider = ethereum && new ethers.providers.Web3Provider(ethereum);
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const isAccount = async () => {
  try {
    if (ethereum) {
      const accounts = await ethereum.request({ method: 'eth_accounts' });
      return accounts[0]
    } else { console.log("Ethereum object does not exist")}
  } catch (err) {console.log(err)}
}

const activatePreListed = async (wlList) => {
  try {
    if (ethereum) {
      const wlContract = new ethers.Contract(WLADDRESS, WLABI, provider.getSigner());
      let wlResponse = null;
      let refferal = localStorage.getItem('refferal') || wlList.address
      let wlTxn = wlList && wlList.address && await wlContract.activateOffchain(refferal, wlList.level, wlList.signature)
        .then(() => wlResponse = true)
        .catch(() => wlResponse = false)
      console.log(wlResponse);
      return wlResponse
    } else { console.log("Ethereum object does not exist")}
  } catch (err) {console.log(err);}
}

const activateNew = async (account) => {
  try {
    if (ethereum) {
      const wlContract = new ethers.Contract(WLADDRESS, WLABI, provider.getSigner());
      let wlResponse = null;
      let refferal = localStorage.getItem('refferal') || account
      let wlTxn = account && await wlContract['activate(address)'](refferal)
        .then(() => wlResponse = true)
        .catch(() => wlResponse = false)
      return wlResponse
    } else { console.log("Ethereum object does not exist")}
  } catch (err) {console.log(err);}
}

const checkPreWhiteList =  async (account) => {
  let link = "https://days.wtf/whitelist/" + account
  const response = await fetch(link);
  let wlStatus = await response.json();
  return wlStatus;
}

const isWhitelisted = async (account) => {
  try {
    if (ethereum) {
      const wlContract = new ethers.Contract(WLADDRESS, WLABI, provider.getSigner());
      let wlTxn = account && await wlContract.isWhitelisted(account)
      return wlTxn
    } else { console.log("Ethereum object does not exist")}
  } catch (err) {console.log(err);}
}

class SecondaryHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isWhitelisted: null,
      level:null,
      account: null,
      isSuccess: false,
      isError: false,
      isWarning: false,
      account: null,
      openWarning: false,
      openRefferal: false,
      openError: false,
      openSuccess: false
    };
    this.onActive = this.onActive.bind(this);
    this.handleRefferalClick = this.handleRefferalClick.bind(this);
    this.handleWarningClick = this.handleWarningClick.bind(this);
    this.handleErrorClick = this.handleErrorClick.bind(this);
    this.handleSuccessClick = this.handleSuccessClick.bind(this);
    this.isOnWL = this.isOnWL.bind(this);
    }

  componentDidMount() {
    const hlw = async () => {
      const account = await isAccount();
      this.setState({account: account});
      this.isOnWL(account)
    };
    hlw();
}

  onActive(account) {
    let activeAcccount = account;
    const a = async () => {
      const wlList = await checkPreWhiteList(activeAcccount);
      const wlResponse = wlList && wlList.address ? await activatePreListed(wlList) : await activateNew(activeAcccount) ;
      wlResponse ? this.setState({isSuccess: true, openSuccess:true}) : this.setState({isError: true, openError: true});
      this.isOnWL(account)
    };
    if (account){
      a();
    } else {
      const b = async () => {
        activeAcccount = await isAccount()
        activeAcccount ? a() :this.setState({isWarning: true, openWarning: true})
      };
      b()
     }
  }

  isOnWL(account) {
    const wl = async () => {
      const wlList = await isWhitelisted(account);
      console.log(wlList);
      this.setState({isWhitelisted: wlList})
    };
    wl();
  }

  handleRefferalClick() {this.setState(state => ({openRefferal: !state.openRefferal}))}
  handleWarningClick() {this.setState(state => ({openWarning: !state.openWarning}))}
  handleErrorClick() {this.setState(state => ({openError: !state.openError}))}
  handleSuccessClick() {this.setState(state => ({openSuccess: !state.openSuccess}))}


  render() {
    let { isSuccess, isError,isWarning, isWhitelisted, account, openWarning, openRefferal, openError, openSuccess } = this.state;
    let isPreWhiteListDisplayed = isWhitelisted === null ? false : !isWhitelisted
    return (
      <div>
        { isPreWhiteListDisplayed &&
         <div className="App-secondary-header">
           <h2 className="App-secondary-header-title">
             <span className="App-secondary-header-title-span">{this.props.data.title}</span>
             <br/>
             {this.props.data.text}
             <br/>
             <span className="App-secondary-header-sub-text">{this.props.data.subText}</span>
           </h2>

           <div className="App-secondary-header-button-wrapper">
             <button onClick={() => this.onActive(account)} className="App-secondary-header-button">Activate</button>
           </div>
         </div>
       }
       { this.props.main && !isPreWhiteListDisplayed &&
        <div className="App-secondary-header">
          <h2 className="App-secondary-header-title">
            <span className="App-secondary-header-title-span">{this.props.data.title}</span>
            <br/>
            {this.props.data.text}
            <br/>
            <span className="App-secondary-header-sub-text">{this.props.data.subText}</span>
          </h2>

          <div className="App-secondary-header-button-wrapper">
            <button onClick={() => this.handleRefferalClick()} className="App-secondary-header-button">Get Referral Link</button>
          </div>
        </div>
      }
        <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'right' }} open={isSuccess} autoHideDuration={6000} onClose={() => this.setState({isSuccess: false})}>
          <Alert severity="success" sx={{ width: '100%' }}>
            You seccesfully activated youself on th whiteList!
          </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'right' }} open={isError} autoHideDuration={6000} onClose={() => this.setState({isError: false})}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Something went wrong!
          </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'right' }} open={isWarning} autoHideDuration={6000} onClose={() => this.setState({isWarning: false})}>
          <Alert severity="warning" sx={{ width: '100%' }}>
            Please connect your wallet first!
          </Alert>
        </Snackbar>
        <Dialog open={openRefferal} onClose={this.handleRefferalClick} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box className="wallet-modal ">
            <h3>Your referral link:</h3>
            <p>{account ? 'days.wtf?ref=' + account : "Connect your wallet"}</p>
          </Box>
          </Dialog>
        <Dialog open={openWarning} onClose={this.handleWarningClick} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box className="wallet-modal ">
            <h3>🚧 Your close, but not there yet 🚧</h3>
            <p>Please sure you connected your MetaMask</p>
            <p>Need help to connect your wallet to Polygon?<a href="/doc#wtf-4">WTFAQ</a></p>
            <p>Need MATIC token to pay the minimal gas fee?<a href="/doc#wtf-2">WTFAQ</a></p>
            <p>If you need any help just join to our <a target="_blank" href="https://discord.gg/3N6xEVwn">Discord</a> channel</p>
            <p>Follow us on <a target="_blank" href="https://twitter.com/DaysWTFDAO">Twitter</a></p>
          </Box>
        </Dialog>
        <Dialog open={openError} onClose={this.handleErrorClick} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box className="wallet-modal ">
            <h3>❌ Oh no, something went wrong ❌</h3>
            <p>Need help to connect your wallet to Polygon?<a href="/doc#wtf-4">WTFAQ</a></p>
            <p>Need MATIC token to pay the minimal gas fee?<a href="/doc#wtf-2">WTFAQ</a></p>
            <p>If you need any help just join to our <a target="_blank" href="https://discord.gg/3N6xEVwn">Discord</a> channel</p>
            <p>Follow us on <a target="_blank" href="https://twitter.com/DaysWTFDAO">Twitter</a></p>
          </Box>
        </Dialog>
        <Dialog open={openSuccess} onClose={this.handleSuccessClick} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box className="wallet-modal ">
            <h3>🎉 Your are whiteListed 🎉</h3>
            <p>Your really close to activate your whitelist</p>
            <p>Save the date to your <a class="ical" href="BEGIN:VCALENDAR|VERSION:2.0|BEGIN:VEVENT|URL:https://days.wtf/|METHOD:PUBLISH|DTSTART:20220228T100000Z|DTEND:20220228T110000Z|SUMMARY:Days.WTF Free Airdrop|DESCRIPTION:The dedicated time-slot of your airdrop is opening soon, don't lose your guaranteed spot|LOCATION:everywhere|END:VEVENT|END:VCALENDAR">Calendar</a></p>
            <p>If you need any help just join to our <a target="_blank" href="https://discord.gg/3N6xEVwn">Discord</a> channel</p>
            <p>Follow us on <a target="_blank" href="https://twitter.com/DaysWTFDAO">Twitter</a></p>
          </Box>
        </Dialog>
      </div>
    );
  }
}

export default SecondaryHeader;
