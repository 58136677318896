import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';
import { ChainId, DAppProvider } from '@usedapp/core';
import { render } from "react-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Dao from "./routes/dao";
import Doc from "./routes/doc";
import DaysHeader from "./components/Header";
import TopBar from "./components/TopBar";
import DaysFooter from "./components/footer";
import Container from '@mui/material/Container';


ReactDOM.render(
  <React.StrictMode>
    <TopBar/>
    <Web3ReactProvider getLibrary={ provider => new Web3Provider(provider)}>
        <DAppProvider>
          <Router>
              <Container maxWidth="md">
              <DaysHeader/>
              <Routes>
               <Route path="/" element={<App />} />
               <Route path="dao" element={<Dao />} />
               <Route path="doc" element={<Doc />} />
             </Routes>
             <DaysFooter/>
             </Container>
          </Router>
        </DAppProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);







// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
