import { Link } from "react-router-dom";
import Container from '@mui/material/Container';
import React, { Component } from "react";
import Carousel from "./Image";
import UspBoxes from "./UspBoxes";
import ExampleDays from "./ExampleDays";
import TimeLine from "./TimeLine";
import WhitelistTable from "./WhiteListTable";
import WhiteListPromoBlock from "./Banner";
import Diagram from "./Diagram";


class PreMint extends Component {
  constructor(props) {
    super(props);
    this.state = {status: "PREMINT"};
}

render() {
  let { status} = this.state;

  return (
      <div>
      <p>Why did we forget the dimension of Time? Time is by no means what it seems. Albert Einstein once wrote: the distinction between past, present and future is only a stubbornly persistent illusion.</p>

      <p>Illusion. Probably nothing. That is for claim-mint-bid-sale now.</p>
      <p>NFTs have so much more potential than just visual art. These are much more than just PFP or visuals, it is about community, fun, access, being a trailblazer.</p>
      <p>
        Days.WTF is a community-owned (CC0) project with a maximized free fair launch. Money flows into a DAO where DAYS owners can vote and submit proposals, like Metaverse integration to demonstrate ownership.
      </p>

      <Carousel />
      <p>Days.WTF is an experimental attempt to bootstrap an inclusive digital community, governance, and treasury. A non-visual NFT with the following new mechanics:</p>
      <UspBoxes />
      <WhiteListPromoBlock data= {{
        title:"Whitelist Airdrop opens up hourly",
        text:"don't lose your guaranteed spot. We wouldn't like you to say:'man I wish I had got into days'" ,
        subText:"",
      }}
      />
      <WhitelistTable />

      <h2>Wen Moon,Ser?</h2>
      <TimeLine />
      <h2>QUADRATIC BIDDING</h2>
      <p>Distribution of the winning bid:</p>
      <ul>
        <li>16% - second-highest bid taker </li>
        <li>8% - third-highest bid taker </li>
        <li>60% - TiTS DAO </li>
        <li>5% - the referral of the winning bid or to a charity selected by the community</li>
        <li>11% - founders of Days.WTF </li>
      </ul>

      <p>Distribution of Days.WTF NFTs</p>
      <ul>
        <li>10,950 free fair airdrop to the community</li>
        <li>1,500 initial mint for founders</li>
        <li>daily 2 NFT release to the winner of the daily auction </li>
      </ul>



      <h2>10,950 Days only for Diamond Hands</h2>
      <p>During airdrop: you can mint past days<br/ >During bid: you bid on the current day and choose +1 past day, you get both if you win</p>

      <Diagram />

      <p>While every day will be available for the highest bidder, minting past days is limited: leap days, first days of the millennium / century / decade / year / month.</p>


      <ExampleDays />

      <h2>A-team</h2>
      <p>O’Day, Manitoba, Canada.</p>
      <ul className="premint-end">
        <li><b>SVERIGERIKE:</b> BOATING ACCIDENT AFICIONADO</li>
        <li><b>Y-AE-A12:</b> IN IT FOR THE MARS.</li>
        <li><b>DODO:</b> WALKS LIKE A DUCK. TALKS LIKE A DUCK.</li>
      </ul>


      </ div>
    );
  }
}

export default PreMint;
