import { Link } from "react-router-dom";
import React, { Component } from "react";
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { useContractCall } from "@usedapp/core";
import { useEthers } from '@usedapp/core';
import { ethers } from "ethers";
import { DataGrid } from '@mui/x-data-grid';
import './whitelist.css';
import WLABI from './wlabi';
import WLADDRESS from '../address';

const getLevel = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const wlContract = new ethers.Contract(WLADDRESS, WLABI, signer);
      const accounts = await ethereum.request({ method: 'eth_accounts' });
      let wlLevel = accounts.length !== 0 && accounts[0] && await wlContract.getReferredCount(accounts[0]);
      return wlLevel && checkLevelCategory(wlLevel.toNumber())
    } else { console.log("Ethereum object does not exist")}
  } catch (err) {
    console.log(err);
  }
}

const getAccount = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const accounts = await ethereum.request({ method: 'eth_accounts' });
      return accounts.length !== 0 && accounts[0]
    } else { console.log("Ethereum object does not exist")}
  } catch (err) {
    console.log(err);
  }
}

const checkLevelCategory = (reffered) => {
  let level = 0;
  if (reffered >=0 && reffered < 10) {return {reffered, level:1}
  } else if (reffered >=10 && reffered < 50) { return {reffered, level:2}
  } else if (reffered >=50 && reffered < 100) { return {reffered, level:3}
  } else if (reffered >=100 && reffered < 500) { return {reffered, level:4}
  } else if (reffered >=500 && reffered < 2000) {return {reffered, level:5}
  } else if (reffered >=2000 && reffered < 10950) {return {reffered, level:6}
  }else { return {reffered, level:7}}
}

const rows = [
  { id: 1, level: 1, dayCategory: 'Normal day', description: 'Activated addresses with less than 10 referrals '},
  { id: 2, level: 2, dayCategory: 'Month', description: 'At least 10 referrals, or own a CryptoPunk / BAYC / Nouns / ASH at block 14200000'},
  { id: 3, level: 3, dayCategory: 'Year', description: 'At least 50 referrals'},
  { id: 4, level: 4, dayCategory: 'Leap day', description: 'At least 100 referrals'},
  { id: 5, level: 5, dayCategory: 'Decade', description: 'At least 500 referrals'},
  { id: 6, level: 6, dayCategory: 'Century', description: 'At least 2000 referrals'},
  { id: 7, level: 7, dayCategory: 'Millennium', description: 'Message signed by BTC genesis block private key and tweeted at @Days.WTF on Twitter'},
];

const columns = [
  { field: 'level', headerName: 'Level', flex: 0.7 },
  { field: 'dayCategory', headerName: 'Date type', flex: 1.5 },
  { field: 'description', headerName: 'Description', flex: 11 },
];


class WhiteListTable extends Component {
  constructor(props) {
    super(props);
    this.state = { reffered: null, account:null, level:null, isToggleOn: true, open: false, setOpen: false};
    this.handleClick = this.handleClick.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
  }

componentDidMount() {
  const gl = async () => {
    const wlLevel = await getLevel();
    wlLevel && this.setState({level: wlLevel.level, reffered: wlLevel.reffered});
    const wlAccount = await getAccount();
    wlAccount && this.setState({account: wlAccount});

  };
  gl();
}

onOpen() {
  console.log("open");
  this.setState(state => ({
  isToggleOn: !state.isToggleOn,
  open: !state.open
}))}

onClose() {
  console.log("close");
  this.setState(state => ({
  isToggleOn: !state.isToggleOn,
  open: !state.open
}))}

handleClick() {
  this.setState(state => ({
  isToggleOn: !state.isToggleOn,
  open: !state.open
}))}

  render() {
    let { level, reffered, open, setOpen, isToggleOn, account} = this.state;
    let selected = level && "div[data-id='" + level + "']"
    selected && document.querySelectorAll(selected) && document.querySelectorAll(selected)[0] && document.querySelectorAll(selected)[0].classList && document.querySelectorAll(selected)[0].classList.add("my-class");
    return (
      <div>
        <h4>Get Access to More Scarce Days: shill the Free Fair Airdrop</h4>
        <p>If you help building Days.WTF community, and get people on the whitelist using your referral link, you can get access to more scarce days. Non-whitelisted addresses can only mint normal days.</p>
        <p>Shiller-matrix for referrals is the following:</p>
          <div style={{ height: 290, width: '100%' }}>
            <DataGrid
              className="grid"
              rows={rows}
              columns={columns}
              hideFooter={true}
              disableColumnMenu={true}
              disableColumnFilter={true}
              columnHeader--alignCenter
              selectRow ={2}
              density = {"compact"}
            />
          </div>
          <p className="table-bottom" >Be part of a ‘Non-Fungible-Non-Visual-Non-Senses’ Community of DayOwners and get your address whitelisted. Your DAY is only one successful transaction away.</p>
          <div className="app-referral-wrapper"><button onClick={() => this.onOpen()} className="app-referral-button">Get Referral Link</button></div>
          <Dialog open={open} onClose={this.handleClick} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box className="wallet-modal ">
              <h3>Your referral link:</h3>
              <p>{account ? 'days.wtf?ref=' + account : "Connect your wallet"}</p>
            </Box>
          </Dialog>
      </div>
    );
  }
}


export default WhiteListTable;
