
function DaysFooter() {
  return (
      <footer className="App-footer">
        <a className="App-link-footer" href="https://discord.gg/8QK6WXJdhj" target="_blank" rel="noopener noreferrer" >Discord</a>
        <a className="App-link-footer" href="https://twitter.com/DaysWTFDAO" target="_blank" rel="noopener noreferrer" >Twitter</a>
        <a className="App-link-footer" href="https://reactjs.org" target="_blank" rel="noopener noreferrer" >OpenSea</a>
        <a className="App-link-footer" href="https://polygonscan.com/address/0xDF3A8B34122580a1F61D8382E68687524798a422" target="_blank" rel="noopener noreferrer" >PolygonScan</a>
        <a className="App-link-footer" href="mailto:gm@days.wtf" rel="noopener noreferrer" >Email</a>
        <a className="App-link-footer" href="https://bafybeigy7jkbqkopt2ugh4ioqadk74zo44sb6vhbirbodtpudffvzdwxpm.ipfs.infura-ipfs.io/" target="_blank" rel="noopener noreferrer" >Terms</a>
      </footer>
  );
}

export default DaysFooter;
